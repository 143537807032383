/**
 * @author TECHXONN (Abel Cabeza Román)
 * @created 06/10/2023
 * @description The `Policy` component displays detailed information about an insurance policy, including the policy type, payment frequency, and company contact options. It supports actions like editing, deleting, making claims, and viewing the policy document. The component conditionally renders elements based on the policy's mediation status and the availability of certain data, such as company contact information or the policy document location.
 *
 * - **Editable Fields:** Uses `useForm` from React Hook Form to manage form states and validations, displaying policy details that can be edited if not in read-only mode.
 * - **Action Buttons:** Includes buttons for various actions (e.g., declaring a claim, calling assistance, viewing the policy document) with dynamic visibility based on the policy data.
 * - **PDF Viewing:** Conditionally renders a section to display the policy document as a PDF, with different rendering methods depending on the platform (web or mobile).
 * - **Contact and Claim Options:** Provides direct contact options via phone or email, and a quick way to initiate claims or improve insurance through a chat interface.
 *
 * This component is designed to provide a comprehensive view of an insurance policy, enhancing user interaction through direct actions and detailed information presentation, all within a responsive and user-friendly interface.
 */
import {Box, Button, Column, Flex, Image, Menu, Pressable, Row, Text,} from "native-base";
import React, {useContext, useState} from "react";
import {useTranslation} from "react-i18next";
import {Dimensions, Linking, Platform, StyleSheet, View} from "react-native";
import {theme} from "../../styles/theme";
import {paymentFrequencyConst} from "../../consts/paymentFrequency.const";
import IconBtn from "../IconBtn/IconBtn";
import {useForm} from "react-hook-form";
import FormInput from "../FormInput/FormInput";
import MaterialIcons from "@expo/vector-icons/MaterialIcons";
import {useNavigation} from "@react-navigation/native";
import {API} from "../../queries/api";
import AsyncStorage from "@react-native-async-storage/async-storage";

import WebView from "react-native-webview";
import {goToUtil} from "../../utils/goTo.util";
import Logo from "../../assets/images/Logo";
import {GlobalContext} from "../../consts/globalContext.const";
import {truncateNumUtil} from "../../utils/truncateNum.util";
import {call} from "../TelephoneLink/TelephoneLink ";
import {sendEmail} from "../EmailLink/EmailLink";

const readOnly = true;
const screenHeight = Dimensions.get("window").height;

export default function Policy({data, onDelete, onEdit}) {
    const {t} = useTranslation();
    const {
        control,
        formState: {errors, dirtyFields},
        watch,
        handleSubmit,
    } = useForm({
        defaultValues: {
            ...data,
            expirationDate: data.expirationDate ? new Date(data.expirationDate) : "",
        },
    });

    const [isShowingPdf, setIsShowingPdf] = useState(false);
    const navigation = useNavigation();

    const {user} = useContext(GlobalContext)
    
    const webCall = () => {
        call(data.company.claimPhone ? data.company.claimPhone : data.company.phone);
    };

    const goToChat = async () => {
        const policyTypeId = data.policyTypeId
        const {data: {id}} = await API.post("policy/proposal/create", {policyTypeId, userId: user.id})
        await AsyncStorage.setItem("actualProposalId", id.toString())

        await AsyncStorage.setItem(
            "actualPolicyTypeId",
            policyTypeId.toString()
        );
        goToUtil("chat-bot", navigation, {type: "proposal"});
    };
    const createClaim = async () => {
        const {
            data: {id},
        } = await API.post("policy/claim/create", {
            companyAcceptance: true,
            companyNotification: true,
            policyId: data.id,
        });

        await AsyncStorage.setItem("actualClaimId", id.toString());
        await AsyncStorage.setItem(
            "actualPolicyTypeId",
            data.policyTypeId.toString()
        );
        goToUtil("chat-bot", navigation, {type: "claim"});
    };
    const MenuContext = () => (
        <Menu
            w="190"
            trigger={(triggerProps) => {
                return (
                    <Pressable accessibilityLabel="More options menu" {...triggerProps}>
                        <MaterialIcons
                            name={"more-vert"}
                            color={theme.colors.gray["400"]}
                            size={24}
                        />
                    </Pressable>
                );
            }}
        >
            <Menu.Item onPress={() => onEdit(data)}>{t("Edit")}</Menu.Item>
            <Menu.Item onPress={() => onDelete(data)}>{t("Delete")}</Menu.Item>
        </Menu>
    );

    return (
        <>
            {!isShowingPdf && (
                <>
                    <Flex
                        flexDirection={["column", "row"]}
                        style={styles.container}
                        alignItems={["flex-start", "flex-end"]}
                        justifyContent={"space-between"}
                    >
                        <Box style={styles.menuContext}>
                            {!data.mediate && <MenuContext/>}
                        </Box>
                        <Row space={3} flex={["auto", "50%"]}>
                            {data.company && (
                                <Image
                                    style={styles.image}
                                    resizeMode={"contain"}
                                    source={{
                                        uri: process.env.REACT_APP_IMAGE_URL + data.company.logo,
                                    }}
                                    alt={t("Company logo")}
                                />
                            )}

                            {!data.company && <Logo style={styles.image}/>}
                            <Column>
                                <Text numberOfLines={1} fontSize={"16"} fontWeight={"400"}>
                                    {data.policyType.name}
                                </Text>
                                <Row space={2} alignItems={"flex-end"}>
                                    <Text
                                        numberOfLines={1}
                                        fontSize={"26"}
                                        fontWeight={"600"}
                                        color={"secondary.700"}
                                    >
                                        {truncateNumUtil(
                                            (data.annualPayment /
                                                paymentFrequencyConst.find(
                                                    (paymentFrequency) =>
                                                        paymentFrequency.value === data.frequency
                                                ).fractional))}
                                        €{" "}
                                    </Text>
                                    <Text
                                        mb={0.5}
                                        numberOfLines={1}
                                        fontSize={"18"}
                                        fontWeight={"600"}
                                        color={"secondary.700"}
                                    >
                                        {" "}
                                        {t("to") + " " + t(data.frequency).toLowerCase()}
                                    </Text>
                                </Row>
                                <Button style={theme.components.Button.modifiers.outline}>
                                    <Text
                                        color={theme.colors.secondary["500"]}
                                        onPress={goToChat}
                                    >
                                        {t("Improve insurance")}
                                    </Text>
                                </Button>
                            </Column>
                        </Row>

                        <Column mt={"3"} flex={["auto", "50%"]} justifyContent={["flex-start", "flex-end"]}>
                            <Row flexWrap={"wrap"}>
                                {data.mediate === true && (
                                    <IconBtn
                                        iconLibrary="COMMUNITY"
                                        iconName={"email"}
                                        text={t("Declarar siniestro por email")}
                                        onPress={() => sendEmail("siniestros@prudenzia.es")}
                                    />
                                )}
                                {data.company &&
                                    ((data.company.phonePrefix && data.company.phone) ||
                                        (data.company.claimPhonePrefix &&
                                            data.company.claimPhone)) && (
                                        <IconBtn
                                            iconName={"phone"}
                                            text={t("Call assistance")}
                                            onPress={webCall}
                                        />
                                    )}
                                {data.fileLocation && (
                                    <IconBtn
                                        iconName={"insert-drive-file"}
                                        text={t("See policy")}
                                        onPress={() => setIsShowingPdf(true)}
                                    />
                                )}
                                {
                                    // JHCORE: Desactivado momentaneamente a petición de Victor
                                    // data.mediate && (
                                    //     <IconBtn
                                    //         iconLibrary="COMMUNITY"
                                    //         iconName={"shield-alert"}
                                    //         text={t("Claim" + " sinister")}
                                    //         onPress={createClaim}
                                    //     />
                                    // )
                                }
                            </Row>
                        </Column>
                    </Flex>

                    <Box mt="6" style={styles.container}>
                        <Flex
                            flexDirection={["column", "row"]}
                            justifyContent={"space-between"}
                        >
                            <Box mt={"3"} width={["100%", "auto"]}>
                                <FormInput
                                    control={control}
                                    errors={errors}
                                    placeholder={t("Reference value")}
                                    name={"referenceValue"}
                                    type={"string"}
                                    readOnly={readOnly}
                                    rules={{required: true}}
                                />
                            </Box>
                            <Box mt={"3"} width={["100%", "auto"]}>
                                <FormInput
                                    control={control}
                                    errors={errors}
                                    placeholder={t("Expiration date")}
                                    name={"expirationDate"}
                                    type={"calendar"}
                                    readOnly={readOnly}
                                    rules={{required: true}}
                                />
                            </Box>
                            {data.policyNumber && <Box mt={"3"} width={["100%", "auto"]}>
                                <FormInput
                                    control={control}
                                    errors={errors}
                                    placeholder={t("Policy number")}
                                    name={"policyNumber"}
                                    type={"string"}
                                    readOnly={readOnly}
                                    rules={{required: true}}
                                />
                            </Box>}
                        </Flex>
                    </Box>
                </>
            )}

            {isShowingPdf && (
                <>
                    <Row mb={6}>
                        <Button
                            onPress={() => setIsShowingPdf(false)}
                            style={theme.components.Button.modifiers.cancel}
                        >
                            <Row alignItems={"center"}>
                                <MaterialIcons
                                    name={"chevron-left"}
                                    color={theme.colors.gray["400"]}
                                    size={24}
                                />
                                <Text color={theme.colors.gray["400"]}>{t("Back")}</Text>
                            </Row>
                        </Button>
                    </Row>
                    {data.fileLocation && (
                            <WebView
                                source={{
                                    uri:
                                        "https://docs.google.com/gview?embedded=true&url=" +
                                        process.env.REACT_APP_IMAGE_URL +
                                        data.fileLocation,
                                }}
                                width="100%"
                                height="100%"
                            />
                        )}
                </>
            )}
        </>
    );
}
const styles = StyleSheet.create({
    container: {
        backgroundColor: theme.colors.gray["0"],
        borderRadius: 6,
        padding: 16,
        borderWidth: 1,
        borderColor: theme.colors.gray["100"],
        position: "relative",
    },
    menuContext: {
        position: "absolute",
        right: 10,
        top: 10,
    },
    image: {
        width: 100,
        height: 100,
    },
});
