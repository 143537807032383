/**
 * @author TECHXONN (Abel Cabeza Román)
 * @created 22/07/2023
 * @description This `FormInput` component is a versatile form field designed for React applications, supporting various input types like string, number, select, multiselect, checkbox, radio, calendar, autocomplete, and file picker. It integrates with a form control system through the `control` prop and manages input values and validation rules. The component dynamically renders different input elements based on the `type` prop provided. For read-only fields, it displays the value in a simple text format. The component also includes custom logic for handling enter key presses, validating file sizes for uploads, and customizing select input options. Error handling is implemented to provide user feedback on validation failures. Additionally, it offers an option for customizing the chat text in a chatbot scenario, enhancing user interaction. This component is designed to be highly reusable and adaptable for various form scenarios, simplifying the process of collecting and validating user input.
 */
import {Checkbox, CheckIcon, Column, FormControl, Input, Radio, Select, Text,} from "native-base";
import {Controller} from "react-hook-form";
import React from "react";
import {useTranslation} from "react-i18next";
import {DatePickerInput, es, registerTranslation,} from "react-native-paper-dates";
import {StyleSheet} from "react-native";
import {theme} from "../../styles/theme";
import {PaperProvider} from "react-native-paper";
import InlineSearch from "../InlineSearch/InlineSearch";
import FilePicker from "../FilePicker/FilePicker";
import {formatDateUtil} from "../../utils/formatDate.util";
import {isValidDateUtil} from "../../utils/isDateUtil";
import VehicleBrandSearch from "../VehicleBrandSearch/VehicleBrandSearch";
import MultiSelect from "react-native-multiple-select";

registerTranslation("es", es);

/**
 *
 * @param control
 * @param disabled
 * @param errors
 * @param name
 * @param label
 * @param placeholder
 * @param rules
 * @param type select type value need to ve always string type
 * @param options
 * @param readOnly
 * @param setCustomChatText this is only used with chat bot
 * @param acceptedFileTypes this is only for file type
 * @param onKeyPress
 * @returns {JSX.Element}
 * @constructor
 */
export default function FormInput({
                                      control,
                                      disabled,
                                      errors,
                                      name,
                                      label,
                                      placeholder,
                                      rules = {required: false},
                                      type = "string",
                                      options,
                                      readOnly,
                                      setCustomChatText,
                                      acceptedFileTypes = ["image/jpeg", "image/png"],
                                      onEnterPress = () => {
                                      },
                                  }) {
    const {t} = useTranslation();
    const ReadOnlyFormInput = ({value, placeholder}) => (
        <Column>
            <Text fontWeight={"500"}>{placeholder}</Text>
            <Text>{isValidDateUtil(value) ? formatDateUtil(value) : value}</Text>
        </Column>
    );

    return (
        <>
            <FormControl
                isRequired={rules.required}
                isInvalid={errors && errors[name]}
            >
                {label && (
                    <FormControl.Label>
                        <Text
                            color="gray.400"
                            style={{
                                fontFamily: "Roboto-Light",
                                fontSize: 12,
                            }}
                        >
                            {t(label)}
                        </Text>
                    </FormControl.Label>
                )}
                <Controller
                    control={control}
                    name={name}
                    rules={
                        rules.required
                            ? {...rules, required: t("Field is required")}
                            : rules
                    }
                    defaultValue=""
                    render={({field: {onChange, onBlur, value}}) => (
                        <>
                            {readOnly && (
                                <ReadOnlyFormInput value={value} placeholder={placeholder}/>
                            )}
                            {!readOnly && (
                                <>
                                    {(type === "string" || type === "number") && (
                                        <Input
                                            keyboardType={type === "number" ? "numeric" : "default"}
                                            onKeyPress={({code}) => {
                                                if (code === "Enter") onEnterPress();
                                            }}
                                            disabled={disabled}
                                            onBlur={onBlur}
                                            placeholder={placeholder ? t(placeholder) : t("Introduce a value")}
                                            onChangeText={(val) =>
                                                onChange(
                                                    type === "number"
                                                        ? isNaN(Number(val))
                                                            ? 0
                                                            : Number(val)
                                                        : val
                                                )
                                            }
                                            value={value}
                                            readOnly={readOnly}
                                        />
                                    )}

                                    {(type === "decimal") && (
                                        <Input
                                            keyboardType="decimal-pad"
                                            onKeyPress={({ code }) => {
                                                if (code === "Enter") onEnterPress();
                                            }}
                                            disabled={disabled}
                                            onBlur={onBlur}
                                            placeholder={placeholder ? t(placeholder) : t("Introduce a value")}
                                            onChangeText={(val) => {

                                                let formattedText = val.replace(/[^0-9.]/g, '');
                                                formattedText = val.replace(",", ".");

                                                // Asegurarse de que solo haya un punto decimal
                                                const dotIndex = formattedText.indexOf('.');
                                                if (dotIndex !== -1) {
                                                    formattedText = formattedText.slice(0, dotIndex + 1) + formattedText.slice(dotIndex + 1).replace(/\./g, '');
                                                }

                                                onChange(formattedText)
                                            }}
                                            value={value}
                                            readOnly={readOnly}
                                        />
                                    )}

                                    {type === "select" && (
                                        <Select
                                            selectedValue={value}
                                            minWidth="200"
                                            disabled={disabled}
                                            readOnly={readOnly}
                                            accessibilityLabel={t("Choose an option")}
                                            placeholder={
                                                placeholder ? placeholder : t("Choose an option")
                                            }
                                            _selectedItem={{
                                                bg: "teal.600",
                                                endIcon: <CheckIcon size="5"/>,
                                            }}
                                            onValueChange={(e) => {
                                                onChange(e ); // select parse all data to string
                                                if(setCustomChatText){
                                                    const foundOption = options.find((option) => option.value == e);
                                                    setCustomChatText(
                                                        foundOption ? foundOption.label : ""
                                                    );
                                                }

                                            }}
                                        >
                                            {options.map((option, index) => (
                                                <Select.Item
                                                    key={index}
                                                    label={t(option.label)}
                                                    value={option.value}
                                                />
                                            ))}
                                        </Select>
                                    )}

                                    {type === "multiselect" && (
                                        <MultiSelect
                                            hideTags
                                            items={options}
                                            styleDropdownMenuSubsection={{
                                                padding: 0,
                                                borderRadius: 6,
                                                display: "flex",
                                                alignItems: "center",
                                            }}
                                            styleRowList={{
                                                padding: 13,
                                                height: 46,
                                                justifyContent: "center",
                                            }}
                                            styleTextDropdown={{padding: 13, height: 46}}
                                            styleTextDropdownSelected={{padding: 13, height: 46}}
                                            styleInputGroup={{
                                                padding: 13,
                                                height: 46,
                                                borderTopLeftRadius: 6,
                                                borderTopRightRadius: 6,
                                            }}
                                            tagContainerStyle={{borderRadius: 6}}
                                            styleDropdownMenu={{marginBottom: 0, height: 46}}
                                            uniqueKey="value"
                                            onSelectedItemsChange={onChange}
                                            selectedItems={value}
                                            selectedText={t("Selected")}
                                            selectText={t("Select items")}
                                            searchInputPlaceholderText={t("Search")}
                                            tagRemoveIconColor="#CCC"
                                            tagBorderColor="#CCC"
                                            tagTextColor="#CCC"
                                            selectedItemTextColor={theme.colors.secondary["500"]}
                                            selectedItemIconColor={theme.colors.secondary["500"]}
                                            itemTextColor="#000"
                                            displayKey="label"
                                            searchInputStyle={{color: "#CCC"}}
                                            submitButtonColor={theme.colors.secondary["500"]}
                                            submitButtonText={t("Select")}
                                        />
                                    )}

                                    {type === "checkbox" && (
                                        <Checkbox
                                            onChange={(e) => {
                                                onChange(e);
                                                if(setCustomChatText) {
                                                    setCustomChatText(e ? t("Yes") : t("No"));
                                                }
                                            }}
                                            value={value !== "" ? value : false}
                                            disabled={disabled}
                                            readOnly={readOnly}
                                        >
                                            <Text>{label}</Text>
                                        </Checkbox>
                                    )}

                                    {type === "radio" && (
                                        <Radio.Group name={name} disabled={disabled}
                                                     readOnly={readOnly} value={value} onChange={(e) => {
                                            onChange(e);
                                        }}>
                                            {options.map((option,index) => <Radio value={option.value} my={1} key={index}>
                                                <Text>{option.label}</Text>
                                            </Radio>)}
                                        </Radio.Group>
                                    )}

                                    {type === "calendar" && (
                                        <PaperProvider
                                            theme={paperTheme}
                                            disabled={disabled}
                                            readOnly={readOnly}
                                        >
                                            <DatePickerInput
                                                locale={"es"}
                                                presentationStyle={"formSheet"}
                                                underlineColor="transparent"
                                                style={
                                                    errors && errors[name]
                                                        ? {...styles.date, ...styles.dateError}
                                                        : styles.date
                                                }
                                                value={value}
                                                onChange={onChange}
                                                inputMode="start"
                                            />
                                        </PaperProvider>
                                    )}

                                    {type === "autocomplete" && (
                                        <InlineSearch
                                            keys={["label"]}
                                            onChange={(e) => {
                                                onChange(
                                                    typeof e.value === "number"
                                                        ? e.value.toString()
                                                        : e.value
                                                );
                                                if(setCustomChatText){

                                                    setCustomChatText(e.value);
                                                }
                                            }}
                                            readOnly={readOnly}
                                            options={options}
                                            disabled={disabled}
                                        />
                                    )}
                                    {(type === "car_brand" ||
                                        type === "car_model" ||
                                        type === "car_version" ||
                                        type === "motorbike_brand" ||
                                        type === "motorbike_model" ||
                                        type === "motorbike_version") && (
                                        <VehicleBrandSearch
                                            typeOfVehicle={
                                                type.substring(0, 3) === "car" ? "car" : "motorbike"
                                            }
                                            onChange={(e) => {
                                                onChange(e.id);
                                                if(setCustomChatText){

                                                    setCustomChatText(e.name);
                                                }
                                            }}
                                            type={type}
                                            readOnly={readOnly}
                                            disabled={disabled}
                                        />
                                    )}
                                    {/*{(type === "address") &&*/}
                                    {/*    <AddressSearch*/}
                                    {/*        onChange={e => {*/}
                                    {/*            onChange(e.id)*/}
                                    {/*            setCustomChatText(e.name)*/}
                                    {/*        }} type={name} readOnly={readOnly} disabled={disabled}/>*/}
                                    {/*}*/}
                                    {type === "file" && (
                                        <FilePicker
                                            onChange={onChange}
                                            value={value}
                                            isInvalid={errors && errors[name]}
                                            acceptedFileTypes={acceptedFileTypes}
                                        />
                                    )}
                                </>
                            )}
                        </>
                    )}

                />
                <FormControl.ErrorMessage>
                    {errors && errors[name] && errors[name].message}
                </FormControl.ErrorMessage>
            </FormControl>
        </>
    );
}

const styles = StyleSheet.create({
    date: {
        height: 48,
        backgroundColor: "white",
        borderWidth: 1,
        borderColor: theme.colors.gray["100"],
        fontSize: 12,
        borderRadius: 6,
        color: theme.colors.gray["700"],
        fontFamily: "Roboto-Light",
    },
    dateError: {
        borderColor: theme.colors.error["700"],
        borderWidth: 2,
    },
});
const paperTheme = {
    colors: {
        surface: "white",
        primary: theme.colors.secondary["500"],
    },
};
